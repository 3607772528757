@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,300;0,400;0,500;0,600;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poiret+One&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  width: 100vw;
  height: 100vh;
  background-color: white;
}
